const Loader = (()=>{
    return(
      <div className="d-flex justify-content-center align-items-center loader-center">
        <div>
        <span className="dot1"></span>
        <span className="dot2"></span>
        <span className="dot3"></span>
        </div>
      </div>
    )
  })
  export default Loader;